import React from "react"
import PropTypes from "prop-types"
import SEO from "../components/seo"

import { graphql } from "gatsby"
import { LocaleContext } from "../components/layout"
import DetailView from "../components/Templates/detailView"
import IS_CORPORATE from "../config/siteConfig"

const Datenschutz = ({
  data: { content: contentCorporate, contentVentures },
  pageContext: { locale },
  location,
}) => {
  const lang = React.useContext(LocaleContext)
  //const i18n = lang.i18n[lang.locale]
  const content = IS_CORPORATE ? contentCorporate : contentVentures

  return (
    <>
      <SEO
        title={"Datenschutz"}
        description={content.data.seo_datenschutz_beschreibung.text}
        keywords={
          content.data.seo_datenschutz_keywords.text
            ? content.data.seo_datenschutz_keywords.text.split(", ")
            : [`Datenschutz`]
        }
      />
      <DetailView>
        <section id="impressum">
          <div className="container">
            <div
              className="content"
              dangerouslySetInnerHTML={{
                __html: content.data.datenschutz.html,
              }}
            />
          </div>
        </section>
      </DetailView>
    </>
  )
}

export default Datenschutz

Datenschutz.propTypes = {
  data: PropTypes.shape({
    content: PropTypes.object.isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
}

export const pageQuery = graphql`
  query DatenschutzQuery($locale: String!) {
    content: prismicHomepage(lang: { eq: $locale }) {
      data {
        title {
          text
        }
        datenschutz {
          html
        }
        seo_datenschutz_beschreibung {
          text
        }
        seo_datenschutz_keywords {
          text
        }
      }
    }
    contentVentures: prismicHomepageVentures(lang: { eq: $locale }) {
      data {
        title {
          text
        }
        datenschutz {
          html
        }
        seo_datenschutz_beschreibung {
          text
        }
        seo_datenschutz_keywords {
          text
        }
      }
    }
  }
`
